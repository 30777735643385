








































import { Component, Prop, Vue } from 'vue-property-decorator'
import CloudUploadIcon from '@/app/ui/assets/cloud_upload_icon.vue'
import DropzoneCloseIcon from '@/app/ui/assets/dropzone_close_icon.vue'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: {
    CloudUploadIcon,
    DropzoneCloseIcon,
  },
})

/**
 * description: string
 * Show input description
 *
 * preview: string
 * Default image preview
 *
 * maxFileSize: number
 * Max file size in KB
 *
 * text: string
 * Text that is displayed when idle
 *
 * dropText: string
 * Text that is displayed when the file dragged
 *
 */
export default class PNDropzone extends Vue {
  @Prop({ type: String }) private description?: string
  @Prop({ type: String, default: '' }) private preview?: string
  @Prop({ type: String }) private accept?: string
  @Prop({ type: Number, default: 1 }) private maxFileSize!: number // in KB
  @Prop({ type: String, default: 'Upload Image' }) private text!: string
  @Prop({ type: String, default: 'Drop Image' }) private dropText!: string
  @Prop({ type: Boolean, default: false }) private noPreview!: boolean
  @Prop({ type: Number, default: 0 }) private minWidth!: number
  @Prop({ type: Number, default: 0 }) private minHeight!: number

  fileName: string | null = null
  base64Preview: string | null = null
  dragOver = false
  error = ''

  get imagePreview(): string {
    if (this.base64Preview && !this.noPreview) {
      return this.base64Preview
    }

    return this.preview as string
  }

  get getText(): string {
    if (this.dragOver) {
      return this.dropText
    }

    return this.text
  }

  private onFileChange(files: FileList) {
    try {
      if (files && files.length > 0) {
        if (this.accept && !this.accept.split(',').includes(files[0].type)) {
          throw new Error(`Pastikan kembali format file, ${this.accept}`)
        }

        if (files[0].size / 1024 > this.maxFileSize) {
          throw new Error(
            `Pastikan kembali format file dan ukuran file tidak lebih dari 1 MB`
          )
        }

        this.error = ''
        this.fileName = files[0].name

        // file reader
        const reader = new FileReader()
        reader.onload = async e => {
          const image = new Image()

          image.src = e.target?.result as string

          image.onload = () => {
            if (this.minWidth > 0 && image.width < this.minWidth) {
              this.error = `Pastikan kembali resolusi file minimal: ${this.minWidth}x${this.minHeight} pixel`

              return
            }

            if (this.minHeight > 0 && image.height < this.minHeight) {
              this.error = `Pastikan kembali resolusi file minimal: ${this.minWidth}x${this.minHeight} pixel`

              return
            }
          }

          if (!this.error)
            this.base64Preview = e.target?.result as string
        }
        reader.readAsDataURL(files[0])
        // end of file reader

        if (!this.error)
          this.$emit('input', files[0])
      }
    } catch (e) {
      const error = e as { message: string }
      this.error = error.message
    }
  }

  private drop({ dataTransfer }: { dataTransfer: DataTransfer }) {
    this.dragOver = false
    this.$emit('change', dataTransfer)
    this.onFileChange(dataTransfer.files)
  }

  private onInputFileChange(event: Event) {
    const target = event.target as HTMLInputElement
    this.$emit('change', target.files)
    if (target.files) this.onFileChange(target.files)
  }

  private removeImage(e: Event) {
    e.preventDefault()
    if (this.base64Preview) {
      this.base64Preview = ''
      this.fileName = ''
      const input = this.$refs.inputFile as HTMLInputElement
      input.value = ''
      this.$emit('input', null)
    } else {
      this.$emit('delete', this.$attrs.id)
    }
  }

  private dragover() {
    this.dragOver = true
  }

  private dragleave() {
    this.dragOver = false
  }
}
