













































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  and,
  or,
  url as urlValidation,
  requiredUnless,
  requiredIf,
} from 'vuelidate/lib/validators'
import dayjs from 'dayjs'
import controller from '@/app/ui/controllers/PNController'
import routeController, {
  Data,
  Data as RouteData,
} from '@/app/ui/controllers/RouteController'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import TextEditor from '@/app/ui/components/TextEditor/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import PNTextInput from '../components/PNTextInput/index.vue'
import PNDropdown from '../components/PNDropdown/index.vue'
import PNDatePicker from '../components/PNDatePicker/index.vue'
import { PushNotification } from '@/domain/entities/PN'
import { semanticVersioning } from '@/app/infrastructures/misc/ValidationRules'
import PNDropzone from '../components/PNDropzone/index.vue'
import Note from '@/app/ui/components/Note/Note.vue'
import * as TypeData from '@/app/ui/components/OptionBox/interfaces'
import * as dataConstant from '@/app/infrastructures/misc/Constants/pushNotification'

@Component({
  mixins: [validationMixin],
  components: {
    Note,
    Button,
    Modal,
    LoadingOverlay,
    PNTextInput,
    PNDropdown,
    PNDatePicker,
    OptionBox,
    ImageUpload,
    TextEditor,
    PNDropzone,
  },
})
export default class EditPNPage extends Vue {
  controller = controller
  routeController = routeController
  dataConstant = dataConstant
  confirmationModal = false
  successModal = false
  todayDate = dayjs().format('YYYY-MM-DD')
  isValidate = false
  isImageValid = true
  isImageFromFile = false
  form = {
    title: '',
    wording: '',
    content: '',
    targetUser: <Record<string, string>>(<unknown>{}),
    fileUsers: new File([], ''),
    cities: <Data[]>[],
    membership: <string[]>[],
    platformOS: <Record<string, string>>dataConstant.platformOSOptions[0],
    platformValidation: dataConstant.platformValidationOptions[0] as Record<
      string,
      string
    >,
    platformVersion: '',
    publishDate: '',
    publishTime: '',
    pnImage: <File[] | Array<undefined>>[],
    targetUrl: '',
    ctaName: '',
    category: <Record<string, string | undefined>>(<unknown>[]),
    imageOption: null as Record<string, string> | null,
  }
  preview = ''
  hasChanged = {
    title: false,
    wording: false,
    content: false,
    targetUser: false,
    fileUsers: false,
    cities: false,
    membership: false,
    platformVersion: false,
    publishDate: false,
    publishTime: false,
    pnImage: false,
    targetUrl: false,
    ctaName: false,
  }
  releaseEditMembership = false

  created(): void {
    controller.getDetailOnEdit(this.$route.params.id)
  }

  @Validations()
  validations(): dataConstant.ValidationsInterface {
    let validate = {
      form: {
        category: { required },
        targetUser: { required },
        fileUsers: { name: {} },
        membership: {},
        cities: {},
        platformVersion: {
          requiredIf: and(
            requiredIf(
              () =>
                this.form.targetUser.value ===
                this.controller.targetOptions[4].value
            ),
            semanticVersioning
          ),
        },
        title: { required, maxLength: maxLength(70) },
        wording: { required, maxLength: maxLength(100) },
        content: {
          required,
          maxLength: (value: string) => Utils.stripTags(value).length <= 1000,
        },
        publishDate: { required },
        publishTime: { required },
        targetUrl: {
          url: and(
            requiredUnless(() => !this.form.ctaName),
            or(urlValidation, (value: string) =>
              value.startsWith('lionparcel://app')
            )
          ),
        },
      },
    }

    if (this.form.targetUser.value === controller.targetOptions[0].value) {
      validate = {
        form: {
          ...validate.form,
          fileUsers: {
            ...validate.form.fileUsers,
            name: { required },
          },
        },
      }
    }

    if (this.form.targetUser.value === controller.targetOptions[2].value) {
      validate = {
        form: {
          ...validate.form,
          cities: {
            minLength: (value: Array<Record<string, number | string>>) =>
              value.length > 0,
          },
        },
      }
    }

    if (this.form.targetUser.value === controller.targetOptions[3].value) {
      validate = {
        form: {
          ...validate.form,
          membership: {
            minLength: (value: Array<Record<string, number | string>>) =>
              value.length > 0,
          },
        },
      }
    }

    return validate
  }

  @Watch('pnDetail')
  onDetailChanged(val: PushNotification): void {
    if (val.targetUsers === controller.targetOptions[2].value) {
      routeController.getCityList()
    }

    if (val.id !== undefined) {
      let categoryLabel = <string>val.notificationCategory?.toLocaleLowerCase()
      categoryLabel =
        <string>categoryLabel.charAt(0).toUpperCase() + categoryLabel.slice(1)

      const membership: string[] = []

      if (val.notificationData) {
        val.notificationData.membershipLevel.forEach(val => {
          if (val.selected) {
            membership.push(String(val.levelID))
          }
        })
      } else {
        if (val.membershipLevel) {
          membership.push(...val.membershipLevel.split(','))
        }
      }

      this.form = {
        title: val.title || '',
        wording: val.wording || '',
        content: val.content || '',
        targetUser:
          this.controller.targetOptions.find(
            item => item.value === val.targetUsers
          ) || {},
        fileUsers: new File([], `${val.selectedUser}`),
        cities: [],
        membership: membership,
        platformOS:
          dataConstant.platformOSOptions.find(
            item => item.value === val.platformOS
          ) || {},
        platformValidation:
          dataConstant.platformValidationOptions.find(
            item => item.value === val.platformValidation
          ) || {},
        platformVersion: val.platformVersion || '',
        publishDate: dayjs(this.controller.pnDetail.publishDate).format(
          'YYYY-MM-DD'
        ),
        publishTime: dayjs(this.controller.pnDetail.publishDate).format(
          'HH:mm:ss'
        ),
        targetUrl: val.targetUrl || '',
        ctaName: val.ctaName || '',
        pnImage: <File[] | Array<undefined>>[],
        category: {
          label: categoryLabel,
          value: val.notificationCategory,
        },
        imageOption: dataConstant.imageOptions.find(option => {
          const type = controller.pnDetail.gifUrl ? 'animation' : 'static'

          return option.value === type
        }) as { label: string; value: string },
      }
    }

    this.preview = val.imageUrl || val.gifUrl || ''
  }

  @Watch('routeController.cityData')
  onRouteDataChanged(val: Data[]): void {
    this.form.cities =
      val.filter(item =>
        this.pnDetail.cities?.includes(item.value.toString())
      ) || []
  }

  get membershipOptions(): TypeData.GroupOption<string, string>[] {
    if (this.pnDetail.notificationData) {
      if (this.releaseEditMembership) {
        return controller.listProgramMembership.map(val => {
          return {
            label: val.label,
            value: String(val.value),
          }
        })
      }

      // TODO: delete this condition after Backend release
      return this.pnDetail.notificationData.membershipLevel.map(val => {
        return {
          label: val.levelName,
          value: String(val.levelID),
        }
      })
    }

    return controller.membershipOptions
  }

  @Watch('membershipOptions')
  onChangedMembershipOptions(
    val: TypeData.GroupOption<string, string>[]
  ): void {
    if (this.pnDetail.notificationData) {
      this.form.membership = this.form.membership.filter(
        data => val.map(k => k.value).indexOf(data) !== -1
      )
    }
  }

  @Watch('controller.errorCreateNotification')
  onErrorCreateNotification(val: string | null): void {
    if (typeof val === 'string') {
      if (val === '') {
        this.successModal = true
      } else {
        const onErrorProgramMembership = 'active'
        if (
          val.toLowerCase().includes(onErrorProgramMembership) &&
          this.isTargetMembership
        ) {
          this.form.membership = []
          setTimeout(() => {
            this.$notify({
              title: 'Update Push Notification Failed',
              text: val,
              type: 'warning',
              duration: 3000,
            })
          }, 500)
          controller.getListProgramMembership()
          window.scroll(0, 0)
        } else {
          this.$notify({
            title: 'Update Push Notification Failed',
            text: val,
            type: 'error',
            duration: 5000,
          })
        }
      }
    }
  }

  get pnDetail(): PushNotification {
    return controller.pnDetail
  }

  get imagePN(): string[] | File[] {
    if (this.form.pnImage.length > 0) {
      return <File[]>this.form.pnImage
    } else if (this.preview.length > 0) {
      return [this.preview]
    } else {
      return []
    }
  }

  get fileAttachmentName(): string | null {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.form.fileUsers ? this.form.fileUsers.name! : null
  }

  get routeOptions(): RouteData[] {
    return routeController.cityData
  }

  private stripTags(input: string): string {
    return Utils.stripTags(input)
  }

  private onInputContent(value: string): void {
    this.form.content = value
  }

  private onFileChange($event: File): void {
    this.form.fileUsers = $event
  }

  // TODO: use this on OptionBox as emit @click when releaseEditMembership is enabled
  /*
  private setMembershipTier(value: string): void {
    this.hasChanged.membership = true
    if (this.form.membership.includes(value)) {
      this.form.membership = this.form.membership.filter(item => item !== value)
    } else {
      this.form.membership.push(value)
    }
  }
  * */

  private onUpdatePN(): void {
    this.isValidate = true
    this.setAllChanged()
    if (!this.$v.form.$invalid) {
      this.confirmationModal = false
      controller.updatePN({
        id: this.$route.params.id,
        title: this.form.title,
        wording: this.form.wording,
        content: this.form.content,
        publishDate: this.form.publishDate,
        publishTime: this.form.publishTime,
        pnImage: this.form.pnImage[0],
        targetUrl: this.form.targetUrl,
        ctaName: this.form.ctaName,
        category: this.form.category,
        imageOption: this.form.imageOption as Record<string, string>,
        programMembershipIDs: this.form.membership.map(val => +val),
      })
    } else {
      this.$notify({
        title: 'Update Push Notification',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private setAllChanged() {
    this.hasChanged = {
      title: true,
      wording: true,
      content: true,
      targetUser: true,
      fileUsers: true,
      cities: true,
      membership: true,
      platformVersion: true,
      publishDate: true,
      publishTime: true,
      pnImage: true,
      targetUrl: true,
      ctaName: true,
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'PNListPage' })
  }

  private onImageUpload($event: File) {
    this.form.pnImage.splice(0, 1, $event)
  }

  private onRemoveImage(index: number) {
    this.form.pnImage.splice(index, 1)
  }

  @Watch('form.imageOption')
  private changeImageOption(
    _: Record<string, string>,
    oldValue: Record<string, string> | null
  ): void {
    if (oldValue !== null) {
      this.form.pnImage = <File[] | Array<undefined>>[]
      this.preview = ''
    }
  }

  get acceptedFormat(): string {
    let fileFormat = 'GIF'
    if (this.form.imageOption?.value === 'static') {
      fileFormat = 'JPG/PNG'
    }

    return fileFormat
  }

  get acceptedImages(): string {
    let fileFormat = 'image/gif'
    if (this.form.imageOption?.value === 'static') {
      fileFormat = 'image/jpg,image/jpeg,image/png'
    }

    return fileFormat
  }

  get maxFileSize(): number {
    let maxFile = 2048
    if (this.form.imageOption?.value === 'static') {
      maxFile = 1024
    }

    return maxFile
  }

  get showNoteEmptyProgramMembership(): boolean {
    // TODO: delete condition this.releaseEditMembership after Backend release
    return (
      controller.hasDataProgramMembership === 'empty' &&
      this.releaseEditMembership
    )
  }

  get isTargetMembership(): boolean {
    return this.form.targetUser.value === controller.targetOptions[3].value
  }

  get isInvalidMembershipLevelTarget(): boolean {
    return this.isTargetMembership && this.showNoteEmptyProgramMembership
  }
}
