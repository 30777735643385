var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-pn-wrapper"},[(_vm.controller.isLoading || _vm.controller.isLoadingRoute)?_c('LoadingOverlay'):_vm._e(),_c('Modal',{ref:"modal-confirm",attrs:{"visible":_vm.confirmationModal,"title":"Confirmation"}},[_c('div',{staticClass:"text-center",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('p',[_vm._v("Are you sure want to update push notification:")]),_c('p',[_vm._v(_vm._s(_vm.pnDetail.title))])]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"mr-3",attrs:{"buttonStyle":"outline"},on:{"click":function () {
            _vm.confirmationModal = false
          }}},[_vm._v("Check Again ")]),_c('Button',{ref:"btn-update",on:{"click":function () {
            _vm.onUpdatePN()
          }}},[_vm._v("Yes, Update ")])],1)]),_c('Modal',{ref:"modal-success",attrs:{"visible":_vm.successModal,"title":"Push Notification Updated","type":"success","body":"The push notification has been updated and add to the list"},on:{"change":_vm.onCloseSuccessModal}}),_c('h2',{staticClass:"text-bgMatterhorn text-2xl font-bold mb-10"},[_vm._v(" "+_vm._s(_vm.pnDetail.title)+" ")]),_c('div',{staticClass:"w-full max-w-160 flex flex-col"},[_c('PNDropdown',{attrs:{"fieldLabel":"Target User","id":"target-user","name":"targetUser","placeholder":"Select the target of user","required":true,"disabled":true,"clearOnSelect":false,"allowEmpty":false,"searchable":false,"options":_vm.controller.targetOptions,"errorMessage":!_vm.$v.form.targetUser.required && _vm.hasChanged.targetUser
          ? 'field is required'
          : undefined},on:{"input":function () {
          _vm.hasChanged.targetUser = true
        }},model:{value:(_vm.form.targetUser),callback:function ($$v) {_vm.$set(_vm.form, "targetUser", $$v)},expression:"form.targetUser"}}),(_vm.form.targetUser.value === _vm.controller.targetOptions[0].value)?_c('PNTextInput',{attrs:{"disabled":true,"fieldLabel":"List of User","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","fieldDescription":"Max size : 5 Mb, Format : Excel","id":"pn-user-list-file","name":"pnUserListFile","type":"file","placeholder":"Attach file","required":true,"fileName":_vm.fileAttachmentName,"errorMessage":!_vm.$v.form.fileUsers.name.required ? 'field is required' : undefined},on:{"change":_vm.onFileChange}}):_vm._e(),(_vm.form.targetUser.value === _vm.controller.targetOptions[2].value)?_c('PNDropdown',{attrs:{"disabled":true,"multiple":true,"loading":_vm.controller.isLoadingRoute,"fieldLabel":"Select city","id":"pn-city","name":"pnCity","placeholder":"Name of city","options":_vm.routeOptions,"errorMessage":!_vm.$v.form.cities.minLength && _vm.hasChanged.cities
          ? 'At least select one city'
          : undefined},on:{"input":function () {
          _vm.hasChanged.cities = true
        }},model:{value:(_vm.form.cities),callback:function ($$v) {_vm.$set(_vm.form, "cities", $$v)},expression:"form.cities"}}):_vm._e(),(_vm.isTargetMembership && !_vm.showNoteEmptyProgramMembership)?_c('OptionBox',{attrs:{"fieldLabel":"Select Membership Tier","fieldDescription":!_vm.$v.form.membership.minLength && _vm.hasChanged.membership
          ? 'At least select one membership tier'
          : 'You can select more than 1',"disabled":true,"required":true,"id":"pn-membership","name":"pnMembership","preselected":_vm.form.membership,"options":_vm.membershipOptions}}):_vm._e(),(_vm.isInvalidMembershipLevelTarget)?_c('note',{staticClass:"mb-6",attrs:{"text":"There is no active membership levels at the moment, choose another Target User"}}):_vm._e(),(_vm.form.targetUser.value === _vm.controller.targetOptions[4].value)?_c('div',{staticClass:"flex flex-row md:flex-wrap lg:flex-nowrap gap-4"},[_c('PNDropdown',{attrs:{"fieldLabel":"Platform OS","id":"pn-platform-os","name":"pnPlatformOS","placeholder":"Select the platform OS","disabled":true,"clearOnSelect":false,"allowEmpty":false,"searchable":false,"options":_vm.dataConstant.platformOSOptions},model:{value:(_vm.form.platformOS),callback:function ($$v) {_vm.$set(_vm.form, "platformOS", $$v)},expression:"form.platformOS"}}),_c('PNDropdown',{attrs:{"fieldLabel":"Validation","id":"pn-platform-validation","name":"pnPlatformValidation","placeholder":"Select the validation type","disabled":true,"clearOnSelect":false,"allowEmpty":false,"searchable":false,"options":_vm.dataConstant.platformValidationOptions},model:{value:(_vm.form.platformValidation),callback:function ($$v) {_vm.$set(_vm.form, "platformValidation", $$v)},expression:"form.platformValidation"}}),_c('PNTextInput',{attrs:{"fieldLabel":"Platform Version","fieldDescription":"Version number of application","id":"pn-platform-version","name":"pnPlatformVersion","type":"text","placeholder":"2.4.0","disabled":true,"required":true,"errorMessage":!_vm.$v.form.platformVersion.requiredIf && _vm.hasChanged.platformVersion
            ? 'field is required and must follow semantic version format'
            : undefined},on:{"input":function () {
            _vm.hasChanged.platformVersion = true
          }},model:{value:(_vm.form.platformVersion),callback:function ($$v) {_vm.$set(_vm.form, "platformVersion", $$v)},expression:"form.platformVersion"}})],1):_vm._e(),_c('PNDropdown',{attrs:{"fieldLabel":"Notification's Category","id":"notification-category","name":"notificationCategory","placeholder":"Select notification category","required":true,"clearOnSelect":false,"allowEmpty":false,"searchable":false,"options":_vm.controller.categoryOptions,"loading":_vm.controller.isLoadingCategory,"errorMessage":_vm.$v.form.category.$dirty && !_vm.$v.form.category.required
          ? 'field is required'
          : undefined},model:{value:(_vm.$v.form.category.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.category, "$model", $$v)},expression:"$v.form.category.$model"}}),_c('div',{staticClass:"flex flex-row md:flex-wrap lg:flex-nowrap gap-4"},[_c('PNTextInput',{attrs:{"fieldLabel":"Title","fieldDescription":"The text that will be read by user in the announcement details","withCounter":true,"maxCount":70,"id":"pn-title","name":"pnTitle","type":"textarea","customClass":"h-16-a","placeholder":"Type the title","required":true,"errorMessage":!_vm.$v.form.title.required && _vm.hasChanged.title
            ? 'field is required'
            : !_vm.$v.form.title.maxLength
            ? 'max length is 70'
            : undefined},on:{"input":function () {
            _vm.hasChanged.title = true
          }},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('PNTextInput',{attrs:{"fieldLabel":"Push Notification Wording","fieldDescription":"The text that will be read by user when they check the push notification. You can enter [name of user] on it","withCounter":true,"maxCount":100,"required":true,"id":"pn-wording","name":"pnWording","type":"textarea","customClass":"h-16-a","placeholder":"Type the wording","errorMessage":!_vm.$v.form.wording.required && _vm.hasChanged.wording
            ? 'field is required'
            : !_vm.$v.form.wording.maxLength
            ? 'max length is 100'
            : undefined},on:{"input":function () {
            _vm.hasChanged.wording = true
          }},model:{value:(_vm.form.wording),callback:function ($$v) {_vm.$set(_vm.form, "wording", $$v)},expression:"form.wording"}})],1),_c('TextEditor',{ref:"text-editor",attrs:{"fieldLabel":"Content","value":_vm.form.content,"placeholder":"Describe the content","description":"The text that will be read by user when they check notification detail. You can enter [name of user] on it","maxCount":1000,"required":true,"errorMessage":!_vm.stripTags(_vm.form.content) && _vm.isValidate
          ? 'field is required'
          : _vm.stripTags(_vm.form.content).length > 1000
          ? 'max length is 1000'
          : undefined},on:{"input":function (val) { return _vm.onInputContent(val); }}}),_c('span',{staticClass:"text-gray-500 font-bold text-lg mt-8 mb-4"},[_vm._v("Publish Date and Time")]),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('PNDatePicker',{attrs:{"min-date":_vm.todayDate,"valueFormat":"date","formatted":"DD/MM/YYYY","fieldLabel":"Publish Date","id":"pn-publish-date","name":"pnPublishDate","label":"DD/MM/YYYY","auto-close":true,"required":true,"errorMessage":_vm.$v.form.publishDate.$invalid && _vm.hasChanged.publishDate
            ? 'field is required'
            : undefined},on:{"input":function () {
            _vm.hasChanged.publishDate = true
          }},model:{value:(_vm.form.publishDate),callback:function ($$v) {_vm.$set(_vm.form, "publishDate", $$v)},expression:"form.publishDate"}}),_c('PNDatePicker',{attrs:{"valueFormat":"time","formatted":"HH:mm:ss","fieldLabel":"Publish Time","id":"pn-publish-time","name":"pnPublishTime","label":"HH:MM:SS","required":true,"errorMessage":_vm.$v.form.publishTime.$invalid && _vm.hasChanged.publishTime
            ? 'field is required'
            : undefined},on:{"input":function () {
            _vm.hasChanged.publishTime = true
          }},model:{value:(_vm.form.publishTime),callback:function ($$v) {_vm.$set(_vm.form, "publishTime", $$v)},expression:"form.publishTime"}})],1),_c('PNDropdown',{staticClass:"custom-margin mb-4",attrs:{"fieldLabel":"Image","id":"image","name":"image","allowEmpty":false,"searchable":false,"options":_vm.dataConstant.imageOptions},model:{value:(_vm.form.imageOption),callback:function ($$v) {_vm.$set(_vm.form, "imageOption", $$v)},expression:"form.imageOption"}}),_c('ImageUpload',{ref:"upload-image",attrs:{"accept":_vm.acceptedImages,"value":_vm.imagePN,"text":"Klik Untuk Upload","description":("Max size: " + (_vm.maxFileSize /
          1024) + " Mb, Min resolution: 312 x 121 pixel, format: " + _vm.acceptedFormat),"maxDefault":false,"required":true,"maxFileSize":_vm.maxFileSize},on:{"on-input":function (file, index) { return _vm.onImageUpload(file); },"on-remove":function (index) { return _vm.onRemoveImage(index); }}}),_c('div',{staticClass:"flex flex-row gap-4"},[_c('PNTextInput',{attrs:{"fieldLabel":"Link","id":"pn-link","name":"pnLink","type":"text","placeholder":"Input link or deeplink","errorMessage":!_vm.$v.form.targetUrl.url && _vm.hasChanged.targetUrl
            ? 'Deeplink/URL is not valid. Please enter a valid URL link'
            : undefined},on:{"input":function () {
            _vm.hasChanged.targetUrl = true
          }},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('PNTextInput',{attrs:{"fieldLabel":"CTA Name","id":"pn-cta","name":"pnCta","type":"text","placeholder":"Input CTA name"},on:{"input":function () {
            _vm.hasChanged.ctaName = true
          }},model:{value:(_vm.form.ctaName),callback:function ($$v) {_vm.$set(_vm.form, "ctaName", $$v)},expression:"form.ctaName"}})],1)],1),_c('div',{staticClass:"flex flex-row justify-center mt-10"},[_c('router-link',{attrs:{"to":".."}},[_c('Button',{staticClass:"px-12 py-3 mr-4",attrs:{"buttonStyle":"outline","customPadding":""}},[_vm._v("Cancel ")])],1),_c('Button',{ref:"btn-confirm-save",staticClass:"px-12 py-3",attrs:{"customPadding":"","disabled":_vm.showNoteEmptyProgramMembership},on:{"click":function () {
          _vm.confirmationModal = true
        }}},[_vm._v("Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }